<template>
  <div class="login">
    <div class="login__img-box">
      <img src="../assets/img/logo.svg" alt="">
    </div>
    <el-form :model="form" :rules="rules" ref="form"
             class="login__form shadow-lg rounded-lg"
             @submit.prevent="submitForm()"
    >
      <div class="login__form__title text-gray-800">
        <h1>Authorization</h1>
      </div>
      <el-form-item label="Phone" prop="phone">
        <el-input v-model="form.phone" prefix-icon="el-icon-phone"></el-input>
      </el-form-item>
      <el-form-item label="Password" prop="password">
        <el-input type="password" v-model="form.password" prefix-icon="el-icon-lock"></el-input>
      </el-form-item>
      <el-button class="w-full" type="primary" :loading="loading" native-type="submit">Login</el-button>
    </el-form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Login',
  data() {
    return {
      loading: false,
      form: {
        phone: '',
        password: ''
      },
      rules: {
        phone: [
          {required: true, message: 'Please input phone', trigger: 'blur'},
        ],
        password: [
          {required: true, message: 'Please input password', trigger: 'blur'},
        ]
      }
    }
  },
  methods: {
    submitForm(): void {
      (this.$refs.form as any).validate((valid: boolean) => {
        if (valid) {
          this.loading = true;

          this.$auth.login(this.form.phone, this.form.password).finally(() => {
            this.loading = false
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
});
</script>

<style lang="scss">
.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3rem;

  &__img-box {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 2rem;
  }

  &__form {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;

    &__title {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;

      h1 {
        font-size: 1.5rem;
      }
    }
  }
}
</style>
