import axios, { AxiosStatic } from 'axios'
import { App } from 'vue'

declare module "@vue/runtime-core" {
    export interface ComponentCustomProperties {
        $axios: AxiosStatic;
    }
}

export default {
    install: (app: App) => {
        const base_backend_url: string = (process.env.NODE_ENV === 'production') ? "https://control.chrgfn.com" : "https://control.chrgfn.com";
        axios.defaults.baseURL = `${base_backend_url}/api/v1`;
        app.config.globalProperties.$axios = axios;
    }
}
