
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  created() {
    this.$axios.interceptors.request.use((config) => {
      return Promise.resolve(config);
    }, (error) => {

      this.$notify.error({
        title: 'Connection Error',
        message: 'Application server is temporarily unavailable',
        position:  'top-right'
      });

      return Promise.reject(error);
    });

    this.$axios.interceptors.response.use((response) => {
      return Promise.resolve(response);
    }, (error) => {

      let error_title, message;

      if (error.response && error.response.status) {
        switch (error.response.status) {
          case 403 :
          case 401 :
            error_title = 'Authorization Error';

            message = 'Please check your authentication details';

            this.$auth.logout(true);

            break;

          case 422 :

            error_title = 'Error';

            if (error.response && error.response.data && error.response.data.errors) {
              for (let field in error.response.data.errors) {
                if (error.response.data.errors[field]) {
                  message = error.response.data.errors[field][0]
                }
              }
            }

            break;
        }
      }

      if (error_title && message) {
        this.$notify.error({
          title: error_title,
          message: message,
          position: 'top-right'
        });
      }

      return Promise.reject(error);
    });
  }
});
