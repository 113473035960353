
import { defineComponent } from 'vue';
import DispenserModel from "@/models/DispenserModel";

export default defineComponent({
  name: 'DispensersTable',
  props: {
    dispensers: {
      type: Array as () => Array<DispenserModel>,
      required: true
    },
    loading: {
      type: Boolean as () => boolean,
      required: true
    },
  },
  data() {
    return {
      qMiniId: '' as string,
      qRange: [] as Array<any>,
    }
  },
  mounted() {
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

    this.qRange = [
        oneWeekAgo,
        new Date()
    ]
  },
  computed: {
    filtered(): Array<DispenserModel> {
      return this.dispensers.filter(data => !this.qMiniId.trim() || data.mini_id.toString().toLowerCase().includes(this.qMiniId.trim().toLowerCase()))
    }
  },
  methods: {
    changeRange(value: Array<string>): void {
      this.$emit('changeRange', value)
    }
  }
});
