import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import api_service from './services/api-service'
import auth_service from './services/auth-service'

import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";

declare module "@vue/runtime-core" {
    export interface ComponentCustomProperties {
        $notify: any,
        $confirm: any,
    }
}


createApp(App)
    .use(api_service)
    .use(auth_service)
    .use(router)
    .use(ElementPlus)
    .mount('#app')

